import React from 'react';
import { navigate } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Button from 'components/Button';

const renderCardData = (path, fullWidth, location, date, time, buttons) => (
  <div
    className="beach cursor-pointer"
    onClick={() => path && navigate(path)}
    role="presentation"
  >
    <div className={`text ${fullWidth ? 'textWrapper' : ''}`}>
      <div>
        <h2 className="mb-0">{location}</h2>
        {date && <p>{date}</p>}
        {time && <p>{time}</p>}
      </div>
      <div>
        {buttons?.length &&
          buttons.map((button, index) => (
            <Button
              key={index}
              type="app-default-white-button"
              linkAsButton={true}
              path={button.slug}
            >
              {button.text}
            </Button>
          ))}
      </div>
    </div>
  </div>
);

const Card = ({ img, location, date, path, fullWidth, time, buttons }) => {
  return (
    <>
      {img ? (
        <BackgroundImage fluid={img}>
          {renderCardData(path, fullWidth, location, date, time, buttons)}
        </BackgroundImage>
      ) : (
        renderCardData(path, fullWidth, location, date, time, buttons)
      )}
    </>
  );
};
export default Card;
