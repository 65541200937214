import React from 'react';
import Slider from 'react-slick';
import HeroSection from 'components/HeroSection';
import './heroSectionCarousel.scss';

const HeroSectionCarousel = ({ heroSections = [], isTextWithOrPrefix }) => {
  const settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <div className="hero-section-carousel">
      <div>
        <Slider {...settings}>
          {heroSections?.length &&
            heroSections.map(
              ({ title, description, image, callToAction }, index) => (
                <HeroSection
                  key={`home-section-carousel_${index}`}
                  image={image}
                  title={title}
                  callToActions={callToAction}
                  description={description?.childMarkdownRemark?.html}
                  isTextWithOrPrefix={isTextWithOrPrefix}
                />
              )
            )}
        </Slider>
      </div>
    </div>
  );
};

export default HeroSectionCarousel;
