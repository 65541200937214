import React, { useState } from 'react';
import Popover from 'components/Popover';
import Year from './year';
import Make from './make';
import AuctionType from './auctionType';
import Sort from './sort';
import More from './more';
import Estimate from './estimate';
import styles from './header.module.scss';
import { SORTING_OPTIONS } from 'components/utils/vehiclesFormUtils';
import {
  enabled as moreEnabled,
  getEstimatedValue,
} from 'shared/helpers/vehicles';

const Header = ({
  vehicleCount,
  filters,
  selectFilter,
  selectSort,
  filtersSelected,
  estimatesRangeData,
  defaultSortId,
}) => {
  const [yearPopoverOpen, setYearPopoverOpen] = useState(false);
  const [sortPopoverOpen, setSortPopoverOpen] = useState(false);
  const [morePopoverOpen, setMorePopoverOpen] = useState(false);
  const [auctionTypePopoverOpen, setAuctionTypePopoverOpen] = useState(false);
  const [makePopoverOpen, setMakePopoverOpen] = useState(false);
  const [estimatePopoverOpen, setEstimatePopoverOpen] = useState(false);
  const yearToggle = () => setYearPopoverOpen(!yearPopoverOpen);
  const sortToggle = () => setSortPopoverOpen(!sortPopoverOpen);
  const moreToggle = () => setMorePopoverOpen(!morePopoverOpen);
  const auctionTypeToggle = () =>
    setAuctionTypePopoverOpen(!auctionTypePopoverOpen);
  const makeToggle = () => setMakePopoverOpen(!makePopoverOpen);
  const estimateToggle = () => setEstimatePopoverOpen(!estimatePopoverOpen);

  return (
    <div className={styles.vehiclesHeader}>
      <div className={styles.innerSection}>
        <div className={styles.count}>
          {!!vehicleCount &&
            <p>{vehicleCount > 50 ? '50 +' : vehicleCount} Vehicle Results </p>
          }
        </div>
        <div className={styles.mainList}>
          <div className={styles.listWrapper}>
            <Popover
              label="Year"
              id="year"
              popoverOpen={yearPopoverOpen}
              toggle={yearToggle}
              active={!!filtersSelected.filtersInput.year}
            >
              <Year
                initialValues={
                  filtersSelected.filtersInput.year ?? {
                    start: filters.year?.start ?? '',
                    end: filters.year?.end ?? '',
                  }
                }
                update={values => {
                  yearToggle();
                  selectFilter({ year: values });
                }}
                cancel={() => {
                  if (filtersSelected.filtersInput.year)
                    selectFilter({ year: undefined });
                  yearToggle();
                }}
              />
            </Popover>
            <Popover
              label="Make"
              id="make"
              popoverOpen={makePopoverOpen}
              toggle={makeToggle}
              active={!!filtersSelected.filtersInput.make.length}
            >
              <Make
                make={filtersSelected.filtersInput.make}
                makeList={filters.make}
                update={values => {
                  makeToggle();
                  selectFilter({ make: values });
                }}
                cancel={() => {
                  if (!!filtersSelected.filtersInput.make.length)
                    selectFilter({ make: [] });
                  makeToggle();
                }}
              />
            </Popover>
            <Popover
              containerStyle="estimatePopup"
              label="Estimate"
              id="estimate"
              active={!!filtersSelected.filtersInput.estimate}
              popoverOpen={estimatePopoverOpen}
              toggle={estimateToggle}
            >
              <Estimate
                state={
                  filtersSelected.filtersInput.estimate
                    ? {
                      min: filtersSelected.filtersInput.estimate.start,
                      max: filtersSelected.filtersInput.estimate.end,
                    }
                    : estimatesRangeData.initialData
                }
                rangeData={estimatesRangeData}
                update={value => {
                  const minValue = getEstimatedValue(value.min);
                  const maxValue = getEstimatedValue(value.max);

                  estimateToggle();
                  selectFilter({
                    estimate: {
                      start: String(minValue),
                      end: !value.maxValueExceed
                        ? String(maxValue)
                        : String(estimatesRangeData.realMax),
                    },
                  });
                }}
                cancel={() => {
                  if (filtersSelected.filtersInput.estimate)
                    selectFilter({ estimate: undefined });
                  estimateToggle();
                }}
              />
            </Popover>
            <Popover
              label="Auction Type"
              id="auctionType"
              active={!!filtersSelected.filtersInput.auctionType.length}
              popoverOpen={auctionTypePopoverOpen}
              toggle={auctionTypeToggle}
            >
              <AuctionType
                auctionTypesList={filters.auctionType}
                state={filtersSelected.filtersInput.auctionType}
                update={values => {
                  auctionTypeToggle();
                  selectFilter({ auctionType: values });
                }}
                cancel={() => {
                  if (!!filtersSelected.filtersInput.auctionType.length)
                    selectFilter({ auctionType: [] });
                  auctionTypeToggle();
                }}
              />
            </Popover>
            <Popover
              label="More"
              active={moreEnabled(filtersSelected.filtersInput)}
              id="vehiclesMore"
              popoverOpen={morePopoverOpen}
              toggle={moreToggle}
            >
              <More
                venueList={filters.venue}
                itemTypeList={filters.itemType}
                auctionYearList={filters.auctionYear}
                state={filtersSelected.filtersInput}
                hasReservePrice={filters.hasReservePrice}
                onlineBiddingAvailable={filters.onlineBiddingAvailable}
                update={values => {
                  moreToggle();
                  selectFilter({ ...values });
                }}
                cancel={values => {
                  if (moreEnabled(filtersSelected.filtersInput))
                    selectFilter({ ...values });
                  moreToggle();
                }}
              />
            </Popover>
            <Popover
              showCaretDown={true}
              active={filtersSelected.sortBy !== defaultSortId}
              label="Sort"
              id="sort"
              popoverOpen={sortPopoverOpen}
              toggle={sortToggle}
            >
              <Sort
                selected={SORTING_OPTIONS.find(
                  option => option.id === filtersSelected.sortBy
                )}
                update={value => {
                  selectSort({ sortBy: value.id });
                  sortToggle();
                }}
                sortOptions={SORTING_OPTIONS}
              />
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
