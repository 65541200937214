import { useStaticQuery, graphql } from 'gatsby';

const useStaticQueryGetAllAuctions = () => {
  const query = useStaticQuery(
    graphql`
      query allAuctions {
        allAuctions: allContentfulWebPageAuction {
          nodes {
            title
            slug
            featureImage {
              fluid(maxWidth: 1200) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            auction {
              name
              subEvents {
                __typename
                ... on ContentfulSubEventAuction {
                  startDate
                  endDate
                }
              }
              eventAttendanceMode
              eventStatus
              location {
                name
              }
            }
          }
        }
      }
    `
  );
  return {
    allAuctions: query.allAuctions.nodes,
  };
};

export default useStaticQueryGetAllAuctions;
