import { useStaticQuery, graphql } from 'gatsby';

export const useFeaturedVehicles = () => {
  const query = useStaticQuery(
    graphql`
      query {
        graphqlaws {
          featuredVehicles {
            featured
            objectID
            auctionType
            auctionName
            featuredImage {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              title
            }
            model
            make
            modelYear
            slug
            itemType
            title
            auctionSlug
            auctionEndDate
            upcomingAuctionEndDate
            auctionStartDate
          }
        }
      }
    `
  );
  return {
    vehicles: query.graphqlaws.featuredVehicles || [],
  };
};
