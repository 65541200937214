import React, { useState } from 'react';
import _ from 'lodash';
import LabelCard from 'components/LabelCard';
import Footer from 'components/FilterFooter';
import styles from './auction.module.scss';

const AuctionType = ({ state, update, auctionTypesList, cancel }) => {
  const [value, setValue] = useState(state);
  return (
    <div>
      <div className={styles.auction}>
        {auctionTypesList.map((option, index) => (
          <LabelCard
            label={option}
            key={`${option}${index}`}
            selected={!!_.filter(value, o => o === option).length}
            onClick={() => setValue(_.xor(value, [option]))}
          />
        ))}
      </div>
      <Footer
        updateDisable={!!!value.length}
        clearOnClick={() => cancel()}
        updateOnClick={() => update(value)}
      />
    </div>
  );
};

export default AuctionType;
