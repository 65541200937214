import React from 'react';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import './horizontalCarousel.scss';
import { getAlgoliaItemTitle } from 'shared/helpers/vehicles';
import { VEHICLE_PATH, AUCTION_PATH } from 'utils/pathsConstants';
import { generateFluidImage } from 'utils/image';

const carouselSettings = {
  infinite: true,
  speed: 500,
  variableWidth: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 5000,
  adaptiveHeight: true,
};

const mobileCarouselSettings = {
  ...carouselSettings,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const desktopCarouselSettings = {
  ...carouselSettings,
  slidesToScroll: 1,
};

const CarouselItem = ({
  item: {
    featuredImage,
    auctionName,
    auctionSlug,
    make,
    model,
    modelYear,
    slug,
    title,
    itemType,
  },
  index,
  minHeight,
}) => {
  const width = featuredImage.file?.details?.image?.width;
  const height = featuredImage.file?.details?.image?.height;
  const image = featuredImage?.file?.url
    ? generateFluidImage(740, featuredImage.file.url, 90, width, height)
    : {};
  return (
    <div key={index} className="carousel-item-wrapper ">
      {featuredImage?.file?.url && (
        <GatsbyImage image={image} minHeight={minHeight} className={'img'} />
      )}
      <div className="sliderText">
        <Link
          className="app-primary-button register-online-btn"
          to={auctionSlug ? AUCTION_PATH(auctionSlug) : '#'}
        >
          {auctionName?.toUpperCase()}
        </Link>
        <h1 className="vehicle-label">
          <Link className="cursor-pointer" to={slug ? VEHICLE_PATH(slug) : '#'}>
            {getAlgoliaItemTitle({
              itemType,
              model,
              modelYear,
              make,
              title,
            }).toUpperCase()}
          </Link>
        </h1>
      </div>
    </div>
  );
};

const HorizontalCarousel = ({ items = [], title }) => (
  <div className="horizontalCarousel">
    {!!items.length && (
      <div>
        <h2>{title}</h2>
        <div className="featuredCarouselMobile">
          <Slider {...mobileCarouselSettings}>
            {items.map((item, index) => (
              <CarouselItem item={item} index={index} key={index} />
            ))}
          </Slider>
        </div>

        <div className="featuredCarouselDesktop">
          <Slider {...desktopCarouselSettings}>
            {items.map((item, index) => (
              <CarouselItem
                item={item}
                index={index}
                key={index}
                minHeight={350}
              />
            ))}
          </Slider>
        </div>
      </div>
    )}
  </div>
);

export default HorizontalCarousel;
