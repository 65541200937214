import React, { useState, Fragment } from 'react';
import {
  getAuction,
  getPrice,
  getTag,
  getContentfulItemTitle,
} from 'shared/helpers/vehicles';
import VehicleCard from 'components/VehicleCard';
import Filters from '../filters';
import styles from './vehicles.module.scss';

const Vehicles = ({ soldItems, availableItems }) => {
  const [vehicleType, setVehicleType] = useState('available');
  const items = vehicleType === 'available' ? availableItems : soldItems;

  return (
    <Fragment>
      <Filters
        vehicleType={vehicleType}
        setVehicleType={type => setVehicleType(type)}
      />
      <div className={styles.vehiclesContainer}>
        <div className={styles.vehiclesWrapper}>
          {items?.length &&
            items.map((vehicle, index) => (
              <VehicleCard
                key={`vehicleCard_${index}`}
                fluidImages={vehicle?.images}
                tag={getTag(vehicle)}
                makeModel={getContentfulItemTitle(vehicle)}
                auction={getAuction(vehicle)}
                price={getPrice(vehicle)}
                slug={vehicle.slug}
                arrowCircle={false}
                showDotsOnHover={true}
                salesForceId={vehicle?.salesForceId}
              />
            ))}
          {((!availableItems?.length && vehicleType === 'available') ||
            (!soldItems?.length && vehicleType !== 'available')) && (
            <em className="noResultsMsg">"No results found"</em>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Vehicles;
