import { useStaticQuery, graphql } from 'gatsby';

const useStaticQueryGetPrivateSales = () => {
  const query = useStaticQuery(
    graphql`
      query getPrivateSalesList {
        PrivateSales: allContentfulPrivateSaleListing {
          nodes {
            salePrice
            askingPrice
            slug
            item {
              ...Automobilia
              ...Vehicles
            }
          }
        }
      }

      fragment Automobilia on ContentfulAutomobilia {
        lot {
          hasReservePrice
        }
        id
        title
        salesForceId
        images {
          fluid(maxWidth: 350, maxHeight: 220, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
      }
      fragment Vehicles on ContentfulVehicle {
        id
        make {
          name
        }
        modelYear
        model
        salesForceId
        images {
          fluid(maxWidth: 350, maxHeight: 220, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
          title
        }
      }
    `
  );
  return {
    privateSalesList:
      query.PrivateSales.nodes?.length &&
      query.PrivateSales.nodes
        .filter(({ item }) => item)
        .map(item => ({ ...item, ...item.item, auctionType: 'Private Sale' })),
  };
};

export default useStaticQueryGetPrivateSales;
