import React from 'react';
import moment from 'moment';
import AuctionCard from './AuctionCard';
import './upcomingAuctions.scss';

const UpcomingAuction = ({
  auctions,
  handleClick,
  fullWidth,
  title,
  auctionDate,
  vertical,
  paddingTop,
}) => {
  let className = '';
  className += fullWidth ? 'text-section-web' : '';
  className += vertical ? '' : ' horizontalScroll';

  return (
    <div className="upcoming-auctions" style={{ paddingTop }}>
      <div className="titleSection">
        {title && (
          <div>
            {title && <h2>{title}</h2>}
            {auctionDate && (
              <h5>{moment(auctionDate).format('DD MMMM YYYY')}</h5>
            )}
          </div>
        )}
      </div>
      <div className={`text-section ${className}`}>
        {auctions?.length > 0 &&
          auctions.map((auction, index) => (
            <AuctionCard
              key={index}
              img={auction.img}
              location={auction.location}
              date={auction.date}
              time={auction.time}
              buttons={auction.eventDetailButtons}
              path={auction.path}
              info={auction.info}
              subInfo={auction.subInfo}
              mailAddress={auction.mailAddress}
              handleClick={handleClick}
              fullWidth={fullWidth}
            />
          ))}
      </div>
    </div>
  );
};

UpcomingAuction.defaultProps = {
  fullWidth: false,
  vertical: true,
  paddingTop: '58px',
};

export default UpcomingAuction;
