import * as React from 'react';
import { graphql } from 'gatsby';
import Webpage from './WebPage';
import SEO from 'components/seo';

export default ({ data, location }) => {
  const { contentfulWebPageDefault } = data;
  return (
    <>
      <SEO title={contentfulWebPageDefault.pageTitle} />
      <Webpage
        sections={contentfulWebPageDefault.sections}
        location={location}
      />
    </>
  );
};

export const query = graphql`
  query WebPage($id: String) {
    contentfulWebPageDefault(contentful_id: { eq: $id }) {
      pageTitle
      sections {
        __typename
        ... on ContentfulWebCollectionOfParagraphsLists {
          title
          collections {
            title
            id
            paragraphs {
              headline
              id
              paragraph {
                childMarkdownRemark {
                  html
                }
              }
              style
            }
          }
        }

        ... on ContentfulWebListOfHeroSections {
          heroSections {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
            callToAction {
              ...callToActions
            }
          }
        }

        ... on ContentfulWebHeroImage {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 1800, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          subTitle
          callToAction {
            ...callToActions
          }
        }

        ... on ContentfulWebParagraphWithHeadline {
          id
          headline
          style
          paragraph {
            childMarkdownRemark {
              html
            }
          }
          callToAction {
            ...callToActions
          }
        }

        ... on ContentfulWebSideBySide {
          ...sideBySide
        }

        ... on ContentfulWebDynamicSections {
          sections
        }

        ... on ContentfulWebListOfCards {
          header
          cards {
            ...cardData
          }
        }

        ... on ContentfulWebCard {
          ...cardData
        }
        ... on ContentfulWebRichTextSection {
          headline
          richText: childContentfulWebRichTextSectionPageDataRichTextNode {
            json
          }
        }

        ... on ContentfulWebListOfContacts {
          id
          title
          contacts {
            telephone
            position
            faxNumber
            email
            department
            name
            image {
              fluid(maxWidth: 240, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }

        ... on ContentfulWebListOfVenues {
          venues {
            name
            address {
              ... on ContentfulAddress {
                streetAddress
                addressCountry
                addressLocality
                addressRegion
                postOfficeBoxNumber
                postalCode
                crossStreets
              }
              ... on ContentfulAddressFreeform {
                addressLocality
                text {
                  text
                }
              }
            }
            image {
              fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }

        ... on ContentfulWebVehicleCatalogueOptions {
          id
          title
          defaultSortOption
          heroBanner {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
            }
            callToAction {
              ...callToActions
            }
          }
        }
        ... on ContentfulWebTimeline {
          steps {
            headline
            style
            paragraph {
              childMarkdownRemark {
                html
              }
            }

            icon {
              file {
                url
              }
            }
          }
        }

        ... on ContentfulWebVideoMedia {
          videoUrl
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }

  fragment callToActions on ContentfulWebCallToAction {
    buttonText
    style
    id
    action {
      ... on ContentfulWebRedirectAction {
        url
        openInANewTab
      }
      ... on ContentfulWebOpenFormAction {
        form
      }
      ... on ContentfulWebDownloadAction {
        file {
          file {
            url
          }
        }
      }
    }
  }

  fragment cardData on ContentfulWebCard {
    title
    id
    textAlign
    style
    description {
      childMarkdownRemark {
        html
      }
    }
    callToAction {
      ...callToActions
    }
    background {
      fluid(maxWidth: 1000, quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
      title
    }
    icon {
      file {
        url
        fileName
      }
    }
  }

  fragment sideBySide on ContentfulWebSideBySide {
    sideBySide: sections {
      headline
      style
      paragraph {
        childMarkdownRemark {
          html
        }
      }
      callToAction {
        ...callToActions
      }
    }
    callToActionList {
      cta {
        ...callToActions
      }
    }
  }
`;
