import React from 'react';
import SECTIONS from 'src/utils/sectionConstants';
import Layout from 'components/Layout';
import HeroSectionCarousel from 'components/HeroSectionCarousel';
import HeroSection from 'components/HeroSection';
import ExpandableList from 'features/expandableList';
import ParagraphWithHeadline from 'features/paragraphWithHeadline';
import DynamicSection from 'features/dynamicSection';
import ListOfCards from 'features/listOfCards';
import SideBySide from 'features/sideBySide';
import RichText from 'features/RichText';
import Teams from 'components/teams';
import UpcomingAuction from 'components/UpcomingAuctions';
import Vehicles from 'features/vehicles';
import VehicleSaleSteps from 'components/VehicleSaleSteps';
import FailureModel from 'components/FailureModel';
import MailSubmittedModal from 'components/mailSubmittedModal';
import YouTubePlayer from 'features/YoutubePlayer';

const WebPage = ({ sections, location }) => {
  const layoutStyle =
    sections?.length &&
    (sections[0].__typename === SECTIONS.ListOfHeroSections ||
      sections[0].__typename === SECTIONS.HeroImage)
      ? {
          transparentHeader: true,
          paddingTop: false,
        }
      : {
          transparentHeader: false,
          paddingTop: true,
        };

  const state = location.state || {};

  return (
    <Layout {...layoutStyle}>
      {state.failedVerification && <FailureModel />}
      {state.RTBEmailSent && (
        <MailSubmittedModal
          isRTB={state.RTBEmailSent}
          isApproved={state.isApproved}
        />
      )}
      {state.emailSent && <MailSubmittedModal />}
      {sections.map((section, index) => {
        switch (section.__typename) {
          case SECTIONS.CollectionOfLists:
            return (
              <ExpandableList
                key={`${section?.__typename}${index}`}
                section={section}
              />
            );
          case SECTIONS.ListOfHeroSections:
            return (
              <HeroSectionCarousel
                key={`${section?.__typename}${index}`}
                heroSections={section?.heroSections}
                isTextWithOrPrefix={true}
              />
            );
          case SECTIONS.HeroImage:
            const {
              image,
              title,
              callToAction,
              description,
              subTitle,
            } = section;
            return (
              <HeroSection
                key={`${section?.__typename}${index}`}
                image={image}
                title={title}
                subTitle={subTitle}
                callToActions={callToAction}
                description={description?.childMarkdownRemark?.html}
                isTextWithOrPrefix={true}
              />
            );
          case SECTIONS.DynamicSections.type:
            return (
              <DynamicSection
                key={`${section?.__typename}${index}`}
                section={section}
              />
            );
          case SECTIONS.ParagraphWithHeadline:
            return (
              <ParagraphWithHeadline
                key={`${section?.__typename}${index}`}
                styleType={section?.style}
                ismarkdown={true}
                description={section?.paragraph?.childMarkdownRemark?.html}
                headline={section?.headline}
                callToAction={
                  section?.callToAction?.length && section.callToAction
                }
              />
            );
          case SECTIONS.SideBySide:
            return (
              <SideBySide
                key={`${section?.__typename}${index}`}
                sections={section?.sideBySide}
                cta={section?.callToActionList?.cta}
              />
            );

          case SECTIONS.ListOfCards:
            return (
              <ListOfCards
                key={`${section?.__typename}${index}`}
                cards={section?.cards?.length ? section.cards : []}
                title={section?.header}
              />
            );
          case SECTIONS.Card:
            return (
              <ListOfCards
                key={`${section?.__typename}${index}`}
                cards={[section]}
                title={section?.header}
              />
            );
          case SECTIONS.RichText:
            return (
              <RichText
                key={`${section?.__typename}${index}`}
                json={section?.richText?.json}
                headline={section?.headline}
                location={location}
              />
            );
          case SECTIONS.ListOfContacts:
            return (
              <Teams
                title={section?.title}
                key={`${section?.__typename}${index}`}
                contacts={section?.contacts}
              />
            );
          case SECTIONS.ListOfVenues:
            return (
              <UpcomingAuction
                auctions={section?.venues?.map(venue => {
                  return {
                    img: venue?.image?.fluid,
                    location: venue?.name,
                    info: venue?.address?.streetAddress ? (
                      <>
                        <span className="d-block">Auction Location</span>
                        <span>
                          {venue?.name}
                          {venue?.address?.streetAddress}
                          {venue?.address?.addressLocality},{' '}
                          {venue?.address?.addressRegion}
                          {venue?.address?.postalCode}
                        </span>
                      </>
                    ) : (
                      `
                      ${venue?.address?.text?.text}
                      ${venue?.address?.addressLocality}
                    `.replace(/undefined/g, '')
                    ),
                    subInfo: venue?.address?.crossStreets || '',
                    mailAddress: 'Mailing Address  +',
                  };
                })}
                auctionDate={new Date()}
              />
            );
          case SECTIONS.VehicleCatalogOptions:
            return (
              <Vehicles
                key={`${section?.__typename}${index}`}
                location={location}
                section={section}
              />
            );
          case SECTIONS.Timeline:
            return (
              <VehicleSaleSteps
                steps={section?.steps}
                key={`${section?.__typename}${index}`}
              />
            );
          case SECTIONS.VideoMedia:
            return (
              <YouTubePlayer
                key={`${section?.__typename}${index}`}
                url={section?.videoUrl}
              />
            );
          default:
            return '';
        }
      })}
    </Layout>
  );
};

export default WebPage;
