import React from 'react';
import Card from './Card';
import { getFormatedDate } from 'shared/helpers/auctions';
import './verticalCardList.scss';

const AuctionsCardList = ({
  auctions = [],
  handleClick,
  fullWidth,
  title,
  subEventAuction,
  vertical,
  paddingTop,
}) => {
  let className = '';
  className += fullWidth ? 'text-section-web' : '';
  className += vertical ? '' : ' horizontalScroll';

  const startYear =
    subEventAuction &&
    new Date(
      getFormatedDate(subEventAuction, 'startDate', 'lessThan').startDate
    ).toLocaleString('en-US', {
      year: 'numeric',
    });

  const endYear =
    subEventAuction &&
    new Date(
      getFormatedDate(subEventAuction, 'endDate', 'greaterThan').endDate
    ).toLocaleString('en-US', {
      year: 'numeric',
    });

  return (
    <div className="auctions-list" style={{ paddingTop }}>
      <div className="titleSection">
        <h2>{title}</h2>
        {subEventAuction?.length && (
          <h5>{`${startYear} ${
            endYear !== startYear ? ' | ' + endYear : ''
          }`}</h5>
        )}
      </div>
      <div className={`text-section ${className}`}>
        {auctions?.length > 0 &&
          auctions.map((auction, index) => (
            <Card
              key={index}
              img={auction.backgroundImage}
              location={auction.name}
              date={auction.date || ''}
              time={auction.time || ''}
              path={auction.slug}
              handleClick={handleClick}
              fullWidth={fullWidth}
              buttons={auction.buttons}
            />
          ))}
      </div>
    </div>
  );
};

AuctionsCardList.defaultProps = {
  title: 'AUCTIONS',
  fullWidth: false,
  vertical: true,
  paddingTop: '58px',
};

export default AuctionsCardList;
