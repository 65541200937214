import {
  getUpcomingAuctions,
  getUpcomingSubEvent,
} from 'services/graphql/queries/auctions/formatedAuctions';
import AuctionsCardList from 'components/AuctionsCardList';
import React from 'react';
import { useStaticQueryGetAuctions } from 'services/graphql/queries/auctions';
import moment from 'moment';
import {
  getEventDateAverage,
  getEventTimeAverage,
} from 'shared/helpers/auctions';
import { ONLINE_ONLY_AUCTION } from 'src/utils/auctionTypes';
import { AUCTION_PATH } from 'src/utils/pathsConstants';

const UpcomingAuctions = ({ fullWidth, auctionType }) => {
  const { subEventAuction } = useStaticQueryGetAuctions({
    variables: {
      todayDate: moment().format('YYYY-MM-DDTHH:mm'),
    },
  });

  const validSubEvents =
    subEventAuction?.length && getUpcomingSubEvent(subEventAuction);

  const auctions =
    validSubEvents?.length &&
    getUpcomingAuctions(validSubEvents)
      .filter(auction => auction.webpage__auction)
      .map(auction => {
        return {
          name: auction.name,
          backgroundImage:
            auction.webpage__auction[0]?.featureImage?.fluid || '',
          date:
            (auction.subEvents?.length &&
              getEventDateAverage(auction.subEvents)) ||
            `${auction.modelYear ? auction.modelYear : ''} ${
              auction.make?.name ? auction.make.name : ''
            } ${auction.model ? auction.model : ''}`,
          buttons: [
            {
              text: 'Event Details',
              slug: AUCTION_PATH(auction.webpage__auction[0]?.slug || ''),
            },
          ],
          time:
            auction.eventAttendanceMode !== ONLINE_ONLY_AUCTION
              ? auction.subEvents?.length &&
                getEventTimeAverage(auction.subEvents)
              : '',
          mode: auction.eventAttendanceMode,
          status: auction.eventStatus
        };
      }).filter(
        auction => auction?.status !== 'EventCancelled'
      );

  return (
    <>
      {!!auctions?.length && (
        <AuctionsCardList
          fullWidth={fullWidth}
          auctions={
            auctionType === 'online'
              ? auctions?.length &&
                auctions.filter(
                  auction => auction?.mode === ONLINE_ONLY_AUCTION
                )
              : auctions
          }
          subEventAuction={validSubEvents}
          title={'Upcoming Auctions'}
        />
      )}
    </>
  );
};

export default UpcomingAuctions;
