import React from 'react';
import YouTubePlayer from 'components/YoutubePlayer';
import styles from './YoutubePlayerWrapper.module.scss';

const YoutubePlayer = ({ url }) => {
  return (
    <div className={styles.videoWrapper}>
      <YouTubePlayer url={url} />
    </div>
  );
};

export default YoutubePlayer;
