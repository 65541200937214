import React from 'react';
import useStaticQueryGetPrivateSales from 'services/graphql/queries/PrivateSales';
import Vehicles from './vehicles';
import './private-sales.scss';

const PrivateSales = () => {
  const { privateSalesList } = useStaticQueryGetPrivateSales();
  const soldItems =
    privateSalesList?.length && privateSalesList.filter(item => item.salePrice);
  const availableItems =
    privateSalesList?.length &&
    privateSalesList.filter(item => !item.salePrice);

  return (
    <div className="private-sales">
      <Vehicles availableItems={availableItems} soldItems={soldItems} />
    </div>
  );
};

export default PrivateSales;
