import React from 'react';
import STYLES from 'src/utils/stylesConstants';
import Side from './side';
import CTA from 'components/CTA';
import styles from './sideBySide.module.scss';

const SideBySide = ({ sections, backgroundColor, marginTop, cta }) => {
  const renderSection = (section, index) => {
    const { headline, paragraph, callToAction, style } = section;
    switch (style) {
      case STYLES.plainText:
      case STYLES.readMoreLess:
        return (
          <Side
            key={index}
            title={headline}
            isMarkdown={true}
            description={paragraph?.childMarkdownRemark?.html}
            collapse={style === STYLES.readMoreLess ? true : false}
            callToActions={callToAction}
          />
        );
      default:
        return '';
    }
  };
  return (
    <div style={{ marginTop, backgroundColor }}>
      <div className={styles.sideBysideWrapper}>
        {sections?.length &&
          sections.map((section, index) => renderSection(section, index))}
        {cta?.length && (
          <div className={styles.ctaWrapper}>{<CTA callToActions={cta} />}</div>
        )}
      </div>
    </div>
  );
};

SideBySide.defaultProps = {
  marginTop: 0,
  backgroundColor: '#f5f5f5',
};

export default SideBySide;
