import React from 'react';
import { useFeaturedVehicles } from 'services/graphql/hooks/featuredVehicles';
import HorizontalCarousel from 'components/HorizontalCarousel';

const FeaturedVehicles = ({ section }) => {
  const { vehicles } = useFeaturedVehicles();

  return (
    <HorizontalCarousel
      items={vehicles.slice(0, 15)}
      title={section?.sections}
    />
  );
};

export default FeaturedVehicles;
