import React from 'react';
import _ from 'lodash';
import Button from 'components/Button';
import styles from './filters.module.scss';

const Filters = ({ vehicleType, setVehicleType }) => {
  return (
    <div className={styles.privateSalesFilterContainer}>
      <Button
        type={
          vehicleType === 'available'
            ? 'app-primary-button'
            : 'app-default-button'
        }
        handleClick={() =>
          _.isFunction(setVehicleType) && setVehicleType('available')
        }
      >
        Available
      </Button>
      <Button
        type={
          vehicleType === 'sold' ? 'app-primary-button' : 'app-default-button'
        }
        handleClick={() =>
          _.isFunction(setVehicleType) && setVehicleType('sold')
        }
      >
        Recently Sold
      </Button>
    </div>
  );
};

export default Filters;
