import React from 'react';
import CTA from 'components/CTA';
import ReadMoreLess from 'components/ReadMoreLess';
import styles from './side.module.scss';

const Side = ({ description, title, collapse, isMarkdown, callToActions }) => {
  return (
    <div className={styles.sideBySide}>
      {title && (
        <div className={styles.headingSection}>
          <h2>{title}</h2>
        </div>
      )}

      <div>
        {collapse ? (
          <ReadMoreLess
            content={description}
            isMarkdown={isMarkdown}
            shadowColor="#f5f5f5"
          />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        )}
      </div>

      {callToActions?.length && (
        <div>
          <CTA
            callToActions={callToActions}
            extraStyle={`${styles.appTransparentBlueButton}`}
          />
        </div>
      )}
    </div>
  );
};

export default Side;
