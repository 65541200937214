import React, { useState } from 'react';
import CopyClipboard from 'components/CopyClipboard';
import EmailIcon from 'svg/EmailIcon';
import TelephoneIcon from 'svg/TelephoneIcon';
import { TITLE, DESCRIPTION } from 'src/data/failureModel';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PhoneNumber from 'components/PhoneNumber'
import './failure-modal.scss';

export default () => {
  const [modal, toggleModal] = useState(true);
  return (
    <div className="failureModal-modal">
      <Modal
        isOpen={modal}
        toggle={() => toggleModal(!modal)}
        backdrop={'static'}
        centered={true}
        className="failure-exit-modal"
      >
        <div className="modal-content">
          <ModalHeader toggle={() => toggleModal(!modal)}></ModalHeader>
          <ModalBody>
            <div>
              <h3 className="modal-title">{TITLE}</h3>
            </div>
            <div className="modal-content">
              <p>{DESCRIPTION}</p>
            </div>
          </ModalBody>
          <ModalFooter className="modal-footer">
            <div className="email-icon">
              <EmailIcon />
              <CopyClipboard id="email" text="bid@goodingco.com" />
            </div>
            <div className="phone-icon">
              <TelephoneIcon /> <a href="tel:310.899.1960">{<PhoneNumber number={'310.899.1960'}></PhoneNumber>}</a>
            </div>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};
