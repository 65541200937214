import React, { useState } from 'react';
import _ from 'lodash';
import Header from 'features/vehicles/header';
import List from 'features/vehicles/list';
import Spinner from 'components/Spinner';
import { GET_VEHICLES_CATALOGUE_DATA } from 'services/graphql/queries/vehicles-catalogue';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from '@apollo/client';
import { getRangeData, mapVenuesToCurrencies } from 'shared/helpers/vehicles';
import Alert from 'components/Alert';
import './vehicles.scss';
import qs from 'qs';
import { navigate } from 'gatsby';
import { SORTING_OPTIONS } from 'components/utils/vehiclesFormUtils';
import CTA from 'components/CTA';
import BackgroundImage from 'gatsby-background-image';
import { useVenues } from 'shared/queries/venues';

const fetchData = (fetchMore, queryParams, currentPage) => {
  fetchMore({
    variables: { ...queryParams, pageNumber: currentPage + 1 },
  });
};

const Vehicles = ({ location, section }) => {
  const defaultSortId = section?.defaultSortOption
    ? SORTING_OPTIONS.find(sort => sort.label === section.defaultSortOption)
        .id ?? 'DEFAULT'
    : 'DEFAULT';

  const searchParams = location.search;
  const queryParams = initQueryParams(searchParams, defaultSortId);

  const [followedVehicles, setFollowedVehicles] = useState([]);
  const updateFollowedVehicles = id =>
    setFollowedVehicles(_.xor(followedVehicles, [id]));

  const { loading, error, data, fetchMore } = useQuery(
    GET_VEHICLES_CATALOGUE_DATA,
    {
      variables: { ...queryParams, pageNumber: 0 },
    }
  );
  const bannerPosition = 6;
  const banner = section.heroBanner && section.heroBanner[0];

  const { venues } = useVenues();
  const currencies = mapVenuesToCurrencies(venues);
  return (
    <div className="vehiclesPage">
      {error ? (
        <Alert color="danger" msg={error.message} />
      ) : loading ? (
        <Spinner />
      ) : !data ? (
        <div> No vehicles</div>
      ) : (
        <InfiniteScroll
          className="infiniteScrollWrapper"
          dataLength={data.getVehicles.vehicles.length}
          next={() => fetchData(fetchMore, queryParams, data.getVehicles.page)}
          loader={<Spinner />}
          hasMore={data.getVehicles.nbPages > data.getVehicles.page + 1}
        >
          <Header
            vehicleCount={data.getVehicles.nbHits}
            filters={data.getVehicles.filters}
            filtersSelected={queryParams}
            selectFilter={value => {
              const params = {
                ...queryParams,
                filtersInput: {
                  ...queryParams.filtersInput,
                  ...value,
                },
              };
              Object.keys(params.filtersInput).forEach(filter => {
                if (params.filtersInput[filter] === undefined)
                  delete params.filtersInput[filter];
              });
              window.scrollTo(0, 0);
              navigate(`/vehicles/?${qs.stringify(params)}`);
            }}
            selectSort={value => {
              window.scrollTo(0, 0);
              navigate(
                `/vehicles/?${qs.stringify({
                  ...queryParams,
                  ...value,
                })}`
              );
            }}
            estimatesRangeData={getRangeData(data.getVehicles.filters.estimate)}
            defaultSortId={defaultSortId}
          />
          {data.getVehicles.vehicles.length === 0 ? (
            <>
              <div className="No-Vehicles-Found-header">No Vehicles Found</div>
              <div className="No-Vehicles-Found-header-sub">
                Try adjusting your filters to get better results.
              </div>
            </>
          ) : (
            <>
              <List
                filtersQuery={queryParams}
                hitsPerPage={data.getVehicles.hitsPerPage}
                offset={0}
                followedVehicles={followedVehicles}
                setFollowedVehicles={id => updateFollowedVehicles(id)}
                vehicles={[...data.getVehicles.vehicles].splice(
                  0,
                  bannerPosition
                )}
                currencies={currencies}
              />
              {banner && (
                <BackgroundImage
                  fluid={banner.image?.fluid}
                  className="upcomingAuctions-banner"
                >
                  <div className="banner-text">
                    {banner.title && <h1>{banner.title}</h1>}
                    {banner.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: banner.description.childMarkdownRemark.html,
                        }}
                      ></div>
                    )}
                    {banner.callToAction && (
                      <CTA callToActions={banner.callToAction} />
                    )}
                  </div>
                </BackgroundImage>
              )}
              <List
                filtersQuery={queryParams}
                hitsPerPage={data.getVehicles.hitsPerPage}
                offset={bannerPosition}
                followedVehicles={followedVehicles}
                setFollowedVehicles={id => updateFollowedVehicles(id)}
                vehicles={[...data.getVehicles.vehicles].splice(
                  bannerPosition,
                  data.getVehicles.vehicles.length
                )}
                currencies={currencies}
              />
            </>
          )}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default Vehicles;

const initQueryParams = (searchParams, defaultSortId) => {
  const initialFilters = {
    make: [],
    auctionType: [],
    itemType: [],
    venue: [],
    auctionYear: [],
  };
  const searchQuery = searchParams ? qs.parse(searchParams.substring(1)) : {};
  if ('filtersInput' in searchQuery) {
    if (
      searchQuery.filtersInput.hasReservePrice &&
      typeof searchQuery.filtersInput['hasReservePrice'] === 'string'
    )
      searchQuery.filtersInput['hasReservePrice'] =
        searchQuery.filtersInput['hasReservePrice'] === 'true';
    if (
      searchQuery.filtersInput.onlineBiddingAvailable &&
      typeof searchQuery.filtersInput.onlineBiddingAvailable === 'string'
    )
      searchQuery.filtersInput.onlineBiddingAvailable =
        searchQuery.filtersInput.onlineBiddingAvailable === 'true';
    Object.keys(searchQuery.filtersInput).forEach(filter => {
      initialFilters[filter] = searchQuery.filtersInput[filter];
    });
  }
  return {
    filtersInput: initialFilters,
    sortBy: searchQuery.sortBy ?? defaultSortId,
  };
};
