import React, { useState } from 'react';
import _ from 'lodash';
import Header from './header';
import styles from './teams.module.scss';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import PhoneNumber from 'components/PhoneNumber';

const Teams = ({ title, contacts }) => {
  const formatData = contacts => {
    let filteredContacts = contacts.filter(contact => {
      return contact.department && contact.position;
    });
    let groupedContacts = _.groupBy(filteredContacts, 'department');
    const keys = Object.keys(groupedContacts);
    let formattedContacts = [];
    keys.forEach(key => {
      formattedContacts.push({ name: key, team: groupedContacts[key] });
    });
    return formattedContacts;
  };

  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0);
  const selectedTeam = formatData(contacts)[selectedTeamIndex].team;

  return (
    <div className={styles.teamWrapper}>
      <Header
        title={title}
        departments={formatData(contacts)}
        selectedTeamIndex={selectedTeamIndex}
        onClick={index => setSelectedTeamIndex(index)}
      />
      <div className={styles.teamMembers}>
        {selectedTeam &&
          selectedTeam
            .filter(team => {
              return team.department && team.position;
            })
            .map((team, index) => {
              return (
                <div key={`team${index}`}>
                  <GatsbyImage title={team.title} image={team?.image?.fluid} />
                  <p className={styles.name}>{team.name}</p>
                  <p>{team.position}</p>
                  <p>
                    <PhoneNumber number={team.telephone}></PhoneNumber>
                  </p>
                  <p>{team.email}</p>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Teams;
