import React, { useState } from 'react';
import _ from 'lodash';
import LabelCard from 'components/LabelCard';
import { enabled } from 'shared/helpers/vehicles';
import Footer from 'components/FilterFooter';
import styles from './more.module.scss';
import { MORE_INITIAL_STATE } from './data';

const isSelected = (state, key, option) =>
  !!_.filter(state[key], o => o === option).length;

const handleClick = (state, key, option, update) => {
  const values = _.xor(state[key], [option]);
  const data = { ...state, [key]: values };
  update(data);
};

const More = ({
  state,
  update,
  venueList,
  itemTypeList,
  auctionYearList,
  hasReservePrice,
  onlineBiddingAvailable,
  cancel,
}) => {
  const [value, setValue] = useState(state);
  return (
    <div>
      <div className={styles.labelCardSection}>
        <div className={styles.innerCard}>
          <div className={styles.innerCardOne}>
            <div>
              <h5>Item Type</h5>
              {itemTypeList.map((option, index) => (
                <LabelCard
                  key={`${option}${index}`}
                  label={option}
                  selected={isSelected(value, 'itemType', option)}
                  onClick={() =>
                    handleClick(value, 'itemType', option, setValue)
                  }
                />
              ))}
              <h5>Reserve</h5>
              {!!hasReservePrice.length ? (
                <LabelCard
                  label={'Without Reserve'}
                  selected={value['hasReservePrice'] !== undefined}
                  onClick={() => {
                    setValue({
                      ...value,
                      hasReservePrice:
                        value['hasReservePrice'] === undefined
                          ? false
                          : undefined,
                    });
                  }}
                />
              ) : (
                ''
              )}
              <h5>Online Bidding</h5>
              {!!onlineBiddingAvailable.length ? (
                <LabelCard
                  label={'Online Bid Eligible'}
                  selected={value.onlineBiddingAvailable !== undefined}
                  onClick={() => {
                    setValue({
                      ...value,
                      onlineBiddingAvailable:
                        value.onlineBiddingAvailable === undefined
                          ? true
                          : undefined,
                    });
                  }}
                />
              ) : (
                ''
              )}
            </div>
            <div>
              <h5>Venue</h5>
              {venueList.map((option, index) => (
                <LabelCard
                  key={`${option}${index}`}
                  label={option}
                  selected={isSelected(value, 'venue', option)}
                  onClick={() => handleClick(value, 'venue', option, setValue)}
                />
              ))}
            </div>
          </div>
          <div className={styles.innerCardYears}>
            <h5>Auction Year</h5>
            <div className={styles.year}>
              {auctionYearList.map((option, index) => (
                <LabelCard
                  key={`${option}${index}`}
                  label={option}
                  containerStyle={styles.yearLabelCard}
                  selected={isSelected(value, 'auctionYear', option)}
                  onClick={() =>
                    handleClick(value, 'auctionYear', option, setValue)
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer
        clearOnClick={() => cancel(MORE_INITIAL_STATE)}
        updateOnClick={() => update(value)}
        updateDisable={!enabled(value)}
      />
    </div>
  );
};

export default More;
